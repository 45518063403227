<template>
  <h2>吾圈六合彩录单机器人</h2>
  <el-row :gutter="20">
    <el-col :span="8">
      <div class="grid-content ep-bg-purple" />
    </el-col>
    <el-col :span="12">
      <div class="grid-content ep-bg-purple" />
      <el-form label-width="auto" label-position="Right" size="large" v-if="!showcz">
        <el-form-item label="手机号">
          <el-input v-model="userName" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="password" type="password" />
        </el-form-item>
        <el-form-item label="服务器id">
          <el-input v-model="serverId" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" style="width: 100%;">登录</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="12">
      <div class="grid-content ep-bg-purple" />
    </el-col>
  </el-row>
  <el-tabs tab-position="left" style="max-height: 800px" class="demo-tabs" type="border-card" v-if="showcz">
    <el-tab-pane label="基础设置">
      <el-row ::gutter="20">
        <el-col :span="12">
          <el-card>
            <template #header>
              <div class="card-header">
                <span style="font-size: large;">{{ xuanzhong }}</span>
              </div>
            </template>
            <el-table :data="groupList" ref="singleTableRef" highlight-current-row
              @current-change="handleSelectionChange">
              <el-table-column property="groupID" label="群ID" width="120" />
              <el-table-column property="name" label="群名称" />
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card>
            <template #header>
              <div class="card-header">
                <span style="font-size: large;">开奖时间设置</span>
              </div>
            </template>
            <p style="font-size: large;">下期封盘时间：<el-date-picker v-model="nextdate" type="datetime"
                placeholder="Pick a Date" format="YYYY-MM-DD HH:mm:ss" date-format="MMM DD, YYYY"
                time-format="HH:mm:ss" /></p>
                <p style="font-size: large;">封盘倒计时 {{ dTime }}</p>
                <el-button type="primary" @click="fsfpmsg" style="width: 100%;">手动封盘</el-button>
          </el-card>
          <el-card>
            <template #header>
              <div class="card-header">
                <span style="font-size: large;">手动开奖设置</span>
              </div>
            </template>
            <div>
              <el-form label-width="auto" label-position="Right" size="large">
                <el-form-item label="下期开奖期号">
                  <el-input v-model="nextiusse" />
                </el-form-item>
                <el-form-item label="本期开奖期号">
                  <el-input v-model="kjissue" />
                </el-form-item>
                <el-form-item label="开奖号码(用,隔开)">
                  <el-input v-model="kjnum" />
                </el-form-item>
                <el-form-item label="开奖时间">
                  <el-date-picker v-model="kjdate" type="datetime" placeholder="Pick a Date"
                    format="YYYY-MM-DD HH:mm:ss" date-format="MMM DD, YYYY" time-format="HH:mm:ss" />
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onKj" style="width: 100%;">提交开奖</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-tab-pane>
    <el-tab-pane label="赔率设置">
      <el-form :model="num">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="龙">
                  <el-input v-model="num.longg" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.longpl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="兔">
                  <el-input v-model="num.tu" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.tupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="猴">
                  <el-input v-model="num.hou" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.houpl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="猪">
                  <el-input v-model="num.zhu" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.zhupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="牛">
                  <el-input v-model="num.niu" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.niupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="鼠">
                  <el-input v-model="num.shu" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.shupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="虎">
                  <el-input v-model="num.hu" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.hupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="蛇">
                  <el-input v-model="num.she" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.shepl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="羊">
                  <el-input v-model="num.yang" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.yangpl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="鸡">
                  <el-input v-model="num.ji" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.jipl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="狗">
                  <el-input v-model="num.gou" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.goupl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="马">
                  <el-input v-model="num.ma" />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="赔率">
                  <el-input v-model="num.mapl" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="红波">
              <el-input v-model="num.hongbo" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.hongbopl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="蓝波">
              <el-input v-model="num.lanbo" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.lanbopl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="绿波">
              <el-input v-model="num.lvbo" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.lvbopl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="金">
              <el-input v-model="num.wxjin" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.wxjinpl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="木">
              <el-input v-model="num.wxmu" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.wxmupl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="水">
              <el-input v-model="num.wxshui" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.wxshuipl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="火">
              <el-input v-model="num.wxhuo" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.wxhuopl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="土">
              <el-input v-model="num.wxtu" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赔率">
              <el-input v-model="num.wxtupl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item label="特码赔率">
              <el-input v-model="num.tmpl" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="大赔率">
              <el-input v-model="num.dapl" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="小赔率">
              <el-input v-model="num.xiaopl" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="单赔率">
              <el-input v-model="num.danpl" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="双赔率">
              <el-input v-model="num.shuangpl" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="组合赔率">
              <el-input v-model="num.zhpl" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="baocunnum" style="width: 100%;">保存</el-button>
        </el-form-item>
      </el-form>

    </el-tab-pane>

    <el-tab-pane label="本期下注流水">
      <el-button  @click="reAll">删除全部</el-button>
      <el-table :data="xiazhumingxi" table-layout="auto"  max-height="800" show-summary  :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column prop="nick" label="用户名" />
        <el-table-column prop="userid" label="用户id" />
        <el-table-column prop="issue" label="下注期号" />
        <el-table-column prop="xz" label="下注明细" />
        <el-table-column prop="ls" label="下注流水" />
        <el-table-column prop="date"  sortable label="最后下注时间" />
        <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" @click="shanchuxiazhu(scope.$index, scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="往期流水详情">
      <el-button  @click="shuaxls">刷新全部</el-button>
      <el-table :data="filterTableData" table-layout="auto" ref="summaryTab" max-height="calc(100vh - 240px)"  show-summary   :default-sort="{ prop: 'create_at', order: 'descending' }">
            <el-table-column prop="nick" label="用户名" />
            <el-table-column prop="userid">
              <template #header>
                <el-input v-model="search" size="small" placeholder="搜索用户id" />
              </template>
            </el-table-column>
            <el-table-column prop="issue">
              <template #header>
                <el-input v-model="searchissue" size="small" placeholder="搜索期号" />
              </template>
            </el-table-column>
            <el-table-column prop="xz" label="下注内容" />
            <el-table-column prop="ls" label="下注流水" />
            <el-table-column prop="kj" label="开奖号码" />
            <el-table-column prop="zjls"  label="中奖流水" />   
            <el-table-column prop="ykls"  label="盈亏流水" />         
            <el-table-column prop="create_at" sortable label="时间"/>
          </el-table>
    </el-tab-pane>
    <el-tab-pane label="查回界面">
      <template #label>
        <el-badge :value="chnum" class="item" :offset="[5, 5]" :hidden="chnum==0">
          查回界面
        </el-badge>
      </template>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-table :data="filterTableData2" table-layout="auto" max-height="800"  :default-sort="{ prop: 'update_at', order: 'descending' }">
            <el-table-column prop="nick" label="用户名" />
            <el-table-column prop="userid">
            <template #header>
              <el-input v-model="search2" size="small" placeholder="搜索用户id" />
             </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" />
            <el-table-column prop="num" label="金额" />
            <el-table-column prop="update_at" sortable label="时间" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button   size="small"
                type="danger" @click="chech(scope.$index, scope.row)">
                  撤回
                </el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-col>
        <el-col :span="12">
          <el-table :data="userch" table-layout="auto"  max-height="800">
            <el-table-column prop="nick" label="用户名" />
            <el-table-column prop="userid" label="用户id" />
            <el-table-column prop="type" label="类型" />
            <el-table-column prop="num" label="金额" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
                  完成
                </el-button>
                <el-button
                  size="small"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                  取消
                </el-button>
              </template>
            </el-table-column>
            
          </el-table>
        </el-col>
      </el-row>
    </el-tab-pane>
    <el-tab-pane label="广告设置">
      <el-button  @click="dialogTableVisible = true">新增广告文本</el-button>
      <el-table :data="adv" table-layout="auto" max-height="800" >
            <el-table-column prop="text" label="发送文本" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button @click="sendadv(scope.$index, scope.row)">
                  发送
                </el-button>
                <el-button @click="readv(scope.$index, scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog v-model="dialogTableVisible" title="新增广告文本">
            <el-input
              v-model="textarea2"
              :autosize="{ minRows: 20, maxRows: 25 }"
              type="textarea"
              placeholder="请输入"
            />
            <el-button  @click="addadv">确定新增</el-button>
          </el-dialog>
    </el-tab-pane>
    <el-tab-pane label="用户账单详情">
      <el-table :data="usernumjl" table-layout="auto"  max-height="calc(100vh - 240px)"  show-summary  :default-sort="{ prop: 'create_at', order: 'descending' }">
            <el-table-column prop="nick" label="用户名" />
            <el-table-column prop="userid" label="用户id" />
            <el-table-column prop="num" label="用户账单余额" />
            <el-table-column prop="djnum" label="用户冻结金额" />
          </el-table>
    </el-tab-pane>
  </el-tabs>

</template>

<script>
import axios from 'axios'
import { ElMessage,ElNotification } from 'element-plus'
import TencentCloudChat from '@tencentcloud/chat';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import myWorker from "worker-loader!./worker.js"

export default {
  name: 'App',
  data() {
    return {
      sizeForm: [],
      userName: '',
      serverId: '',
      password: '',
      accid: '',
      imappid: 0,
      token: '',
      num: {},
      peilv: {},
      chat: null,
      showcz: false,
      kj: [],
      kjtm: 0,
      kjissue: 2024131,
      kjdate: '2024-12-05 21:35:00',
      kjnum: '20,21,22,23,24,25,26',
      nextiusse: 2024132,
      nextdate: '',
      disabled: true,
      groupList: [],
      xuanGroupList: [],
      xuanzhong: '请选择群',
      dateVule: '',
      value1: true,
      sdkj: '当前为自动开奖',
      Crypto: CryptoJS.enc.Utf8.parse("666888\0\0\0\0\0\0\0\0\0\0"),
      xiazhu: [],// 下注信息
      xiazhumingxi: [],
      Worker: new myWorker(),
      fasongfp:false,
      userzhongjiang:[],
      userchjl:[],
      usernumjl:[],
      userch:[],
      userzjls:[],
      dTime:0,
      userxiazhujl:[],
      search:'',
      search2:'',
      search3:'',
      adv:[],
      dialogTableVisible:false,
      textarea2:'',
      searchissue:'',
    }
  },
  components: {
    

  },
  updated(){
    this.$nextTick(()=>{
      this.$refs.summaryTab.doLayout()
    })
  },
  computed:{
    // 计算属性
    filterTableData(){
     let arrd =  this.userxiazhujl.filter(item=>!this.search  || item.userid.toLowerCase().includes(this.search.toLowerCase()))
     arrd = arrd.filter(item=>!this.searchissue  || item.issue.includes(this.searchissue))
     return arrd
    },
    filterTableData2(){
     return this.userchjl.filter(item=>!this.search2 ||   item.userid.toLowerCase().includes(this.search2.toLowerCase()))
    },
    chnum(){
      return this.userch.length
    },
  },
  created(){

  },
  watch: {
    groupList(newV, old) {
      console.log(newV)
      console.log(old)
    },
    usernumjl(newV, old){
      console.log(newV)
      console.log(old)
    },
    xuanGroupList(newV, old) {
      this.xuanzhong = '选择了:' + newV.name
      console.log(old)
      this.disabled = false
    },
    nextdate(newV){
      let thisTime = moment(new Date())
      let fenpan = moment(newV).format("YYYY-MM-DD")
      let fengpandate = moment(fenpan + ' 21:25:00').format('YYYY-MM-DD HH:mm:ss')
      this.dTime = moment(fengpandate).diff(thisTime,'seconds')
    },
  },
  mounted() {
    axios.get('https://jqr.ts28.co/getnum').then(res => {
      if (res.data.code == 200) {
        // 获取成功
        this.num = res.data.data[0]
      } else {
        // 获取失败
        console.log('失败')
      }
    }).catch(err => {
      console.log(err)
    })
    this.getuserchAll()
    this.getusernumAll()
    this.getuserxiazhujlAll()
    
  },
  methods: {
    workerF() {
      // 先清空，在加入
      this.Worker.postMessage({
        type: "stop",
      })
      this.Worker.postMessage({
        type: "start",
        interval: 1000
      })
      this.Worker.onmessage = (event) => {
        // 收息消息
        if (event.data.type == 'message') {
          console.log('这是worker')
          this.dTime--;
          if(parseInt(this.dTime)==0){
            // 封盘

          }
        }
      }
    },
    // 函数
    onSubmit() {
      // 登录
      let data = {
        password: this.password,
        serverId: this.serverId,
        userName: this.userName
      }
      let login = axios.create({
        baseURL: 'https://jqr.ts28.co',
        timeout: 5000, // 请求超时时间
      })
      login.post('/wqlogin', data).then(res => {
        if (res.data.code == 200) {
          //登录成功
          ElMessage({
            message: res.data.msg,
            type: 'success',
          })
          this.accid = res.data.data.accid
          this.toekn = res.data.data.token
          this.imappid = parseInt(res.data.data.imAppid)
          // 登录腾讯im
          let options = {
            SDKAppID: parseInt(res.data.data.imAppid)
          }
          this.chat = TencentCloudChat.create(options);
          let promise = this.chat.login({ userID: res.data.data.accid, userSig: res.data.data.token });
          promise.then((imResponse) => {
            ElMessage({
              message: '连接服务器成功',
              type: 'success',
            })
            setTimeout(() => {
              this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, this.getMsg)
              this.chat.getGroupList().then(imResponses => {
                this.groupList = imResponses.data.groupList
                this.showcz = true
              }).catch(imError => {
                console.warn('getGroupList error:', imError); // 获取群组列表失败的相关信息
              })
            }, 1000)
            if (imResponse.data.repeatLogin === true) {
              // 标识账号已登录，本次登录操作为重复登录。
              console.log(imResponse.data.errorInfo);
              ElMessage({
                message: '重复登录，请退出其他页面登录后再试',
                type: 'error',
              })
            }
          }).catch((imError) => {
            console.log(imError)
            ElMessage({
              message: '连接服务器失败请重试',
              type: 'error',
            })
          });
        } else {
          //登录失败
          ElMessage({
            message: res.data.msg,
            type: 'error',
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleSelectionChange(list) {
      this.xuanGroupList = list

    },
    fsfpmsg(){
      this.dTime = 0
      let fpmsg =this.nextiusse+'期停止下注'+'\n'+'================='
      this.xiazhumingxi.forEach(item=>{
        fpmsg = fpmsg+'\n'+item.nick.slice(0,3)+item.userid.slice(0,5)+'---['+item.xz+']'
      })

      let message = this.chat.createTextMessage({
        to:this.xuanGroupList.groupID,
        conversationType: TencentCloudChat.TYPES.CONV_GROUP,
        payload: {
          text: fpmsg
        },
      })
      this.chat.sendMessage(message).then(imResponse=>{
        console.log(imResponse)
      }).catch(err=>{
        console.log(err)
      })
      
    },
    onKj() {
      // 开奖后判断中奖信息
      this.workerF()
      let kjtext = this.kjnum.split(",")
      let KjTm = kjtext[6]
      let kjtype = {}
      kjtype.tm = parseInt(KjTm)
      // 处理开奖特码生肖、波色、五行、大小、单双、组合
      if(this.num.longg.indexOf(KjTm)!=-1){
        kjtype.sx = '龙'
      }
      if(this.num.tu.indexOf(KjTm)!=-1){
        kjtype.sx = '兔'
      }
      if(this.num.hou.indexOf(KjTm)!=-1){
        kjtype.sx = '猴'
      }
      if(this.num.zhu.indexOf(KjTm)!=-1){
        kjtype.sx = '猪'
      }
      if(this.num.niu.indexOf(KjTm)!=-1){
        kjtype.sx = '牛'
      }
      if(this.num.shu.indexOf(KjTm)!=-1){
        kjtype.sx = '鼠'
      }
      if(this.num.hu.indexOf(KjTm)!=-1){
        kjtype.sx = '虎'
      }
      if(this.num.she.indexOf(KjTm)!=-1){
        kjtype.sx = '蛇'
      }
      if(this.num.yang.indexOf(KjTm)!=-1){
        kjtype.sx = '羊'
      }
      if(this.num.ji.indexOf(KjTm)!=-1){
        kjtype.sx = '鸡'
      }
      if(this.num.gou.indexOf(KjTm)!=-1){
        kjtype.sx = '狗'
      }
      if(this.num.ma.indexOf(KjTm)!=-1){
        kjtype.sx = '马'
      }
      if(this.num.hongbo.indexOf(KjTm)!=-1){
        kjtype.bs = '红'
      }
      if(this.num.lanbo.indexOf(KjTm)!=-1){
        kjtype.bs = '蓝'
      }
      if(this.num.lvbo.indexOf(KjTm)!=-1){
        kjtype.bs = '绿'
      }
      if(this.num.wxhuo.indexOf(KjTm)!=-1){
        kjtype.wx = '火'
      }
      if(this.num.wxjin.indexOf(KjTm)!=-1){
        kjtype.wx = '金'
      }
      if(this.num.wxmu.indexOf(KjTm)!=-1){
        kjtype.wx = '木'
      }
      if(this.num.wxshui.indexOf(KjTm)!=-1){
        kjtype.wx = '水'
      }
      if(this.num.wxtu.indexOf(KjTm)!=-1){
        kjtype.wx = '土'
      }
      if(parseInt(KjTm)%2==0){
        kjtype.ds = '双'
        if(parseInt(KjTm)<25){
          kjtype.dx = '小'
          kjtype.zh = '小双'
        }else{
          kjtype.dx = '大'
          kjtype.zh = '大双'
        }
      }else{
        if(parseInt(KjTm)<24){
          kjtype.dx = '小'
          kjtype.zh = '小单'
        }else{
          kjtype.dx = '大'
          kjtype.zh = '大单'
        }
      }
      this.nextkjdate()
      this.zhongjiang(kjtype,this.xiazhu)
      ElMessage({
            message: '开奖成功',
            type: 'success',
          })
    },
    zhongjiang(kjtype,xiazhu){
      xiazhu.forEach(item=>{
        if(item.type == 'bs'){
          // 是波色下注
          if(item.xz.indexOf(kjtype.bs)!=-1){
            // 判断中奖
            // 获取波色赔率
            if(kjtype.bs == '蓝'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.lanbopl).toFixed(2),
                xz:'蓝',
                ls:item.jine
              })
            }
            if(kjtype.bs == '红'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.hongbopl).toFixed(2),
                xz:'红',
                ls:item.jine
              })
            }
            if(kjtype.bs == '绿'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.lvbopl).toFixed(2),
                xz:'绿',
                ls:item.jine
              })
            }
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
        if(item.type == 'sx'){
          if(item.xz == kjtype.sx){
            // 判断是哪个生肖
            if(kjtype.sx == '龙'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.longpl).toFixed(2),
                xz:'龙',
                ls:item.jine
              })
            }
            if(kjtype.sx == '兔'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.tupl).toFixed(2),
                xz:'兔',
                ls:item.jine
              })
            }
            if(kjtype.sx == '猴'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.houpl).toFixed(2),
                xz:'猴',
                ls:item.jine
              })
            }
            if(kjtype.sx == '猪'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.zhupl).toFixed(2),
                xz:'猪',
                ls:item.jine
              })
            }
            if(kjtype.sx == '牛'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.niupl).toFixed(2),
                xz:'牛',
                ls:item.jine
              })
            }
            if(kjtype.sx == '鼠'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.shupl).toFixed(2),
                xz:'鼠',
                ls:item.jine
              })
            }
            if(kjtype.sx == '虎'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.hupl).toFixed(2),
                xz:'虎',
                ls:item.jine
              })
            }
            if(kjtype.sx == '蛇'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.shepl).toFixed(2),
                xz:'蛇',
                ls:item.jine
              })
            }
            if(kjtype.sx == '羊'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.yangpl).toFixed(2),
                xz:'羊',
                ls:item.jine
              })
            }
            if(kjtype.sx == '鸡'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.jipl).toFixed(2),
                xz:'鸡',
                ls:item.jine
              })
            }
            if(kjtype.sx == '狗'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.goupl).toFixed(2),
                xz:'狗',
                ls:item.jine
              })
            }
            if(kjtype.sx == '马'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.mapl).toFixed(2),
                xz:'马',
                ls:item.jine
              })
            }
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
        if(item.type == 'tm'){
          if(parseInt(kjtype.tm) === parseInt(item.xz)){
            // 是特码
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.tmpl).toFixed(2),
                xz:item.xz,
                ls:item.jine
            })
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
        if(item.type == 'dx'){
          if(kjtype.dx == item.xz){
            if(item.xz == '大'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.dapl).toFixed(2),
                xz:item.xz,
                ls:item.jine
              })
            }else if(item.xz == '小'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.xiaopl).toFixed(2),
                xz:item.xz,
                ls:item.jine
              })
            }
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
        if(item.type == 'ds'){
          if(kjtype.ds == item.xz){
            if (item.xz == '单') {
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.danpl).toFixed(2),
                xz:item.xz,
                ls:item.jine
              })
            } else if(item.xz == '双'){
              this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.shuangpl).toFixed(2),
                xz:item.xz,
                ls:item.jine
              })
            }
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
        if(item.type == 'zh'){
          if(kjtype.zh == item.xz){
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:parseInt(item.jine)*parseFloat(this.num.zhpl).toFixed(2),
                xz:item.xz,
                ls:item.jine
            })
          }else{
            this.userzhongjiang.push({
                nick:item.nick,
                userid:item.userid,
                issue:item.issue,
                zjls:0,
                xz:item.xz,
                ls:item.jine
            })
          }
        }
      })
      // 处理 中奖
      // 处理ls取消冻结->处理中奖ls->加入中奖ls进总账单
      // 取消冻结ls(逻辑是直接等于回冻结流水)
      this.usernumjl.forEach(item=>{
          let ch = this.userch.filter(list=>list.userid === item.userid && list.type === '回')
          let chls = ch.reduce((acc, cur) => parseInt(acc) + parseInt(cur.num), 0)
          let xiazhumingxixi = this.xiazhumingxi.filter(xia=>xia.userid === item.userid)
          item.djnum = parseInt(chls)
          // 处理中奖ls
          if(xiazhumingxixi.length!=0){
            let zj = this.userzhongjiang.filter(u=>u.userid === item.userid)
            console.log(zj)
            let zjls = zj.reduce((acc, cur) => parseInt(acc) + parseInt(cur.zjls), 0)
            // 处理中奖流水
            let zjpost = {
              nick:item.nick,
              userid:item.userid,
              xz:xiazhumingxixi[0].xz,
              ls:xiazhumingxixi[0].ls,
              issue:xiazhumingxixi[0].issue,
              kj:kjtype.tm,
              zjls:zjls,
              ykls:parseInt(zjls)-parseInt(xiazhumingxixi[0].ls)
            }
            item.num = parseInt(item.num)-parseInt(xiazhumingxixi[0].ls)+parseInt(zjls)
            // 下注记录到数据库
            this.postuserxiazhu(xiazhumingxixi[0])
            // post到中奖信息库
            this.postzhongjiang(zjpost)
            // 更新用户金额
            this.putusernum(item)
          }
      })
      this.xiazhu=[]
      this.xiazhumingxi =[]
      this.userzhongjiang =[]
      if(this.xuanGroupList.length!=0){
        this.kjfsmsg()
      }
    },
    kjfsmsg(){
      let resmsg = this.kjissue+'期开奖结果：'+'\n'+this.kjnum+'\n===================='
      this.usernumjl.sort((a, b) =>  b.num - a.num)
      for (let index = 0; index < this.usernumjl.length; index++) {
        if(index%2 != 0){
          continue;
        }else{
          //  判断index+1数组下标是否存在 
          if(this.usernumjl.indexOf(this.usernumjl[index+1])!==-1){
            //   存在 
            resmsg=resmsg+'\n'+this.usernumjl[index].nick.slice(0,2)+this.usernumjl[index].num+"    "+this.usernumjl[index+1].nick.slice(0,2)+this.usernumjl[index+1].num
          }else{
            //    不存在 
            resmsg=resmsg+'\n'+this.usernumjl[index].nick.slice(0,2)+this.usernumjl[index].num
          }
        }
      }
      let message = this.chat.createTextMessage({
        to:this.xuanGroupList.groupID,
        conversationType: TencentCloudChat.TYPES.CONV_GROUP,
        payload: {
          text: resmsg
        },
      })
      this.chat.sendMessage(message).then(imResponse=>{
        console.log(imResponse)
      }).catch(err=>{
        console.log(err)
      })
    },
    nextkjdate() {
      let wk = moment(this.kjdate).format('d')
      let kk =''
      if (wk == 2 || wk == 4) {
        // 周二 周四 加2天
       kk = moment(this.kjdate).add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
      }
      if (wk == 6) {
        // 周六 加3天
        kk  = moment(this.kjdate).add(3, 'days').format('YYYY-MM-DD HH:mm:ss')
      }
        let fenpan = moment(kk).format("YYYY-MM-DD")
        this.nextdate = moment(fenpan + ' 21:25:00').format('YYYY-MM-DD HH:mm:ss')
    },
    baocunnum() {
      axios.put('https://jqr.ts28.co/num', this.num).then(res => {
        if (res.data.code == 200) {
          // 获取成功
          this.num = res.data.data
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
        } else {
          // 获取失败
          console.log('失败')
          ElMessage({
            message: '保存失败',
            type: 'error',
          })
        }
      }).catch(err => {
        console.log(err)
      })

    },
    getMsg(event) {
      const messageList = event.data;
      messageList.forEach((message) => {
        if (message.type === TencentCloudChat.TYPES.MSG_TEXT && this.xuanGroupList.groupID === message.to) {
          let msg = this.textPa(message.payload.text)
          let user = this.usernumjl.filter(item=>item.userid === message.from)
          if(msg == '1'){
            // 查下注详情和流水
            let resmsg=''
            if(user.length!=0){
              let xiazhuindex = this.xiazhumingxi.filter(item => item.userid === message.from && item.issue === this.nextiusse)
              if(xiazhuindex.length!=0){
                // 查询有下注
                resmsg = '@' + message.nick + '\n' + '下注期号：' + this.nextiusse + '\n' + '下注详细：'
                resmsg = resmsg + xiazhuindex[0].xz + '\n' + '下注金额：' + xiazhuindex[0].ls+'\n'+'账单金额：'+user[0].num+'\n'+'冻结金额：'+user[0].djnum+'\n'+'可用余额：'+(parseInt(user[0].num)-parseInt(user[0].djnum))
              }else{
                // 无下注
                resmsg = resmsg  +this.nextiusse + '期无下注记录' + '\n'+'账单金额：'+user[0].num+'\n'+'冻结金额：'+user[0].djnum+'\n'+'可用余额：'+(parseInt(user[0].num)-parseInt(user[0].djnum))
              }
              let rqmsg = this.chat.createTextAtMessage({
                to: message.to,
                conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                payload: {
                  text: resmsg,
                  // 'denny' 'lucy' 都是 userID，而非昵称
                  atUserList: [message.from]
                },
              })
              this.chat.sendMessage(rqmsg).then(imResponse => {
                console.log(imResponse)
              }).catch(err => {
                console.log(err)
              })
            }
          }else if(msg == '取消'){
            // 取消下注
            // 
            if(user.length!=0){
              let qxxzuser = this.xiazhumingxi.filter(item=>item.userid === message.from)
              console.log(qxxzuser)
              if(qxxzuser.length!=0){
                this.xiazhu=this.xiazhu.filter(item=>item.userid !== message.from)
                  this.xiazhumingxi=this.xiazhumingxi.filter(item=>item.userid !== message.from)
                  // 账单记录要恢复
                  let userzd = this.usernumjl.filter(item=>item.userid === message.from)
                  //  冻结流水减去
                  userzd[0].djnum = parseInt(userzd[0].djnum)-parseInt(qxxzuser[0].ls)
                  console.log(userzd)
                  this.putusernum(userzd[0])
                 let resmsg = '@'+message.nick+'取消成功' 
                  let rqmsg = this.chat.createTextAtMessage({
                    to: message.to,
                    conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                    payload: {
                      text: resmsg,
                      // 'denny' 'lucy' 都是 userID，而非昵称
                      atUserList: [message.from]
                    },
                  })
                  this.chat.sendMessage(rqmsg).then(imResponse => {
                    console.log(imResponse)
                  }).catch(err => {
                    console.log(err)
                  })
              }

            }
          }else if(msg.indexOf('改')!=-1){
            // 修改下注
            // 先取消之前下注
            if(user.length!=0){
              let qxxzuser = this.xiazhumingxi.filter(item=>item.userid == message.from && item.issue == this.nextiusse)
              this.xiazhu=this.xiazhu.filter(item=>item.userid !== message.from)
              this.xiazhumingxi=this.xiazhumingxi.filter(item=>item.userid !== message.from)
              // 账单记录要恢复
              let userzd = this.usernumjl.filter(item=>item.userid === message.from)
              //  冻结流水减去
              userzd[0].djnum = parseInt(userzd[0].djnum)-parseInt(qxxzuser[0].ls)
              this.putusernum(userzd[0])
              // 修改下注
              let newmsg =msg.split('改')[1]
              this.xiazhucl(newmsg, message)
            }


          }else if(msg.indexOf('查')!=-1){
            // 修改下注
            // 查分
            let msgarr = this.splitByNumbers(msg)
            let num = msgarr[1]
            this.userch.push({
              nick:message.nick,
              userid:message.from,
              type:'查',
              num:num,
              status:'完成'
            })
            ElNotification({
                  title: '用户上分通知',
                  message:message.nick+message.from+'申请上分，上分金额：'+num+'  请及时处理',
                  type: 'warning',
                  duration:10000
                })
          }else if(msg.indexOf('回')!=-1){
            // 修改下注
            // 回分
            // 要冻结金额djnum
            let msgarr = this.splitByNumbers(msg)
            let num = msgarr[1]
            let user = this.usernumjl.filter(item=>item.userid == message.from)
            if(user.length !== 0){
              // 账单存在用户
              // 判断回分金额是否满足条件
              if(parseInt(user[0].num)-parseInt(user[0].djnum)>=num){
                // 满足, 回分添加进数组
                // 冻结金额
                user[0].djnum = parseInt(user[0].djnum)+parseInt(num)
                // 上传服务器
                this.putusernum(user[0])
                this.userch.push({
                  nick:message.nick,
                  userid:message.from,
                  type:'回',
                  num:num,
                  status:'完成'
                })
                ElNotification({
                  title: '用户下分通知',
                  message:message.nick+message.from+'申请下分，下分金额：'+num+'  请及时处理',
                  type: 'warning',
                  duration:10000
                })
              }else{
                //不满足，提醒可用金额不足
                // 回复失败
                let resmsg = '@' + message.nick + '可用余额不足'
                let rqmsg = this.chat.createTextAtMessage({
                  to: message.to,
                  conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                  payload: {
                    text: resmsg,
                    // 'denny' 'lucy' 都是 userID，而非昵称
                    atUserList: [message.from]
                  },
                })
                this.chat.sendMessage(rqmsg).then(imResponse => {
                  console.log(imResponse)
                }).catch(err => {
                  console.log(err)
                })

              }
              // 判断回分金额是否满足
            }
          }else {
            if(user.length!=0){
              this.xiazhucl(msg, message)
            }
          }
        }
      })
    },
    textPa(bastr) {
      let srcs = bastr;
      let decrypt = CryptoJS.AES.decrypt(srcs, this.Crypto, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypt.toString(CryptoJS.enc.Utf8)
    },
    xiazhucl(msg, message) {
      // 下注文本处理
      // 处理空格
      let date = moment().format("YYYY-MM-DD HH:mm:ss")
      let yedate = moment().format("YYYY-MM-DD")
      let text = msg.split(" ")
      let res = 200
      // 判断下注时间是否超时
      if (this.dTime>=0) {
        console.log('在封盘前')
      } else {
        console.log('在封盘后')
        res = 1
        // 封盘了
        let resmsg = '@' + message.nick + '下注失败,封盘后无效'
        let rqmsg = this.chat.createTextAtMessage({
          to: message.to,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,
          payload: {
            text: resmsg,
            // 'denny' 'lucy' 都是 userID，而非昵称
            atUserList: [message.from]
          },
        })
        this.chat.sendMessage(rqmsg).then(imResponse => {
          console.log(imResponse)
        }).catch(err => {
          console.log(err)
        })
        return res
      }

      console.log(text)
      for (let i = 0; i < text.length; i++) {
        if (text[i].includes('.')) {
          // 是点特码
          // 特码前，金额后
          let tm = text[i].split('.')
          if (parseInt(tm[0]) >= 1 && parseInt(tm[0]) <= 49) {
            if (!isNaN(tm[1])) {
              // 判断是否重复有下注
                // 存在，添加金额
                // 先删除再添加
                this.xiazhu.push({
                userid: message.from,
                nick: message.nick,
                issue: this.nextiusse,
                xz: tm[0],
                jine: tm[1],
                date1: yedate,
                date2: date,
                type: 'tm'
              })
            } else {
              res = 0
            }
          } else {
            res = 0
          }
        } else if (text[i].includes('大单') || text[i].includes('大双') || text[i].includes('小单') || text[i].includes('小双')) {
          // 是组合
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            // 判断是否重复有下注
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'zh'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'zh'
            })
          } else {
            res = 0
          }
        } else if (text[i].includes('双') || text[i].includes('单')) {
          // 是单双
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'ds'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'ds'
            })
          } else {
            res = 0
          }
        } else if (text[i].includes('大') || text[i].includes('小')) {
          // 是大小
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'dx'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'dx'
            })
          } else {
            res = 0
          }
        } else if (text[i].includes('金') || text[i].includes('木') || text[i].includes('水') || text[i].includes('火') || text[i].includes('土')) {
          // 是五行
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'wx'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'wx'
            })
          } else {
            res = 0
          }
        } else if (text[i].includes('龙') || text[i].includes('兔') || text[i].includes('猴') || text[i].includes('猪') || text[i].includes('牛') || text[i].includes('鼠') || text[i].includes('虎') || text[i].includes('蛇') || text[i].includes('羊') || text[i].includes('鸡') || text[i].includes('狗') || text[i].includes('马') || text[i].includes('鼠')) {
          // 是生肖
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'sx'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'sx'
            })
          } else {
            res = 0
          }
        } else if (text[i].includes('红') || text[i].includes('蓝') || text[i].includes('绿')) {
          // 是波色
          // 判断金额是前还是后
          let tt = this.splitByNumbers(text[i])
          if (this.isNumberAtTheBeginning(text[i])) {
            // 金额在前
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[2],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'bs'
            })
          } else if (this.isNumberAtTheEnd(text[i])) {
            // 金额在后
            this.xiazhu.push({
              userid: message.from,
              nick: message.nick,
              issue: this.nextiusse,
              xz: tt[0],
              jine: tt[1],
              date1: yedate,
              date2: date,
              type: 'bs'
            })
          } else {
            res = 0
          }
        } else {
          res = 0
        }
      }
      console.log(date)
      if (res == 0) {
        this.xiazhu = this.xiazhu.filter(item => item.date2 !== date)
      }
      if (res === 200) {
            // 下注成功
            // 回复下注成功
            let xia = this.xiazhu.filter(item => item.userid === message.from && item.issue == this.nextiusse)
            let ls = xia.reduce((acc, cur) => parseInt(acc) + parseInt(cur.jine), 0)
            // 获取用户账单
            console.log(ls)
            let user = this.usernumjl.filter(item=>item.userid === message.from)
            let ch = this.userch.filter(item=>item.userid === message.from && item.type==='回')
            let chls = ch.reduce((acc, cur) => parseInt(acc) + parseInt(cur.num), 0)
            console.log(user)
            // 判断下注金额是否满足条件（总额-冻结金额）
            if(parseInt(user[0].num)-parseInt(chls)>=parseInt(ls)){
              // 正常下注
              // 下注冻结金额
              // 处理冻结金额（用正在查回的金额+全部下注ls金额）
              user[0].djnum = parseInt(chls)+parseInt(ls)
              let date5 = moment().format("YYYY-MM-DD HH:mm:ss")
              let resmsg = '@' + message.nick + '下注成功' + '\n' + '下注期号：' + this.nextiusse + '\n' + '下注详细：'
              let xiazhuxx = ''
              let xiazhuarr = []
              xia.forEach((item)=>{
                let demo = xiazhuarr.filter(list=>list.xz == item.xz)
                if(demo.length>0){
                  // 存在，不处理
                }else{
                  // 不存在 处理
                  let xiazhuzhenghe = xia.filter(yesa => yesa.xz === item.xz)
                  let xiazhuzhenghels = xiazhuzhenghe.reduce((accumulator, current) => parseInt(accumulator) + parseInt(current.jine), 0)
                  xiazhuarr.push({
                    xz:item.xz,
                    jine:xiazhuzhenghels,
                    type:item.type
                  })
                }
              })
              xiazhuarr.forEach(item=>{
                if (item.type == 'tm') {
                  xiazhuxx = xiazhuxx + item.xz + '.' + item.jine + ' '
                } else {
                  xiazhuxx = xiazhuxx + item.xz + item.jine + ' '
                }
              })
              // put冻结金额
              this.putusernum(user[0])
              resmsg = resmsg + xiazhuxx + '\n' + '下注金额：' + ls+'\n'+'账单金额：'+user[0].num+'\n'+'冻结金额：'+user[0].djnum+'\n'+'可用余额：'+(parseInt(user[0].num)-parseInt(user[0].djnum))
              let xiazhuindex = this.xiazhumingxi.findIndex(item => item.userid === message.from && item.issue === this.nextiusse)
              if (xiazhuindex != -1) {
                // 存在，增加修改
                this.xiazhumingxi[xiazhuindex].xz = xiazhuxx
                this.xiazhumingxi[xiazhuindex].ls = ls
                this.xiazhumingxi[xiazhuindex].date = date5
              } else {
                // 不存在，新加
                this.xiazhumingxi.push({
                  userid: message.from,
                  nick: message.nick,
                  xz: xiazhuxx,
                  ls: ls,
                  date: date5,
                  issue: this.nextiusse
                })
              }
              let rqmsg = this.chat.createTextAtMessage({
                to: message.to,
                conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                payload: {
                  text: resmsg,
                  // 'denny' 'lucy' 都是 userID，而非昵称
                  atUserList: [message.from]
                },
              })
              this.chat.sendMessage(rqmsg).then(imResponse => {
                console.log(imResponse)
              }).catch(err => {
                console.log(err)
              })
            }else{
              // 余额不足
              // 清除格式
              this.xiazhu = this.xiazhu.filter(item => item.date2 !== date)
              let resmsg = '@' + message.nick + '下注失败,余额不足'
              let rqmsg = this.chat.createTextAtMessage({
                to: message.to,
                conversationType: TencentCloudChat.TYPES.CONV_GROUP,
                payload: {
                  text: resmsg,
                  // 'denny' 'lucy' 都是 userID，而非昵称
                  atUserList: [message.from]
                },
              })
              this.chat.sendMessage(rqmsg).then(imResponse => {
                console.log(imResponse)
              }).catch(err => {
                console.log(err)
              })
            }
          } else if (res == 0) {
            // 下注失败
            // 回复失败
            let resmsg = '@' + message.nick + '下注失败,格式错误'
            let rqmsg = this.chat.createTextAtMessage({
              to: message.to,
              conversationType: TencentCloudChat.TYPES.CONV_GROUP,
              payload: {
                text: resmsg,
                // 'denny' 'lucy' 都是 userID，而非昵称
                atUserList: [message.from]
              },
            })
            this.chat.sendMessage(rqmsg).then(imResponse => {
              console.log(imResponse)
            }).catch(err => {
              console.log(err)
            })
          }
    },
    splitByNumbers(str) {
      // 使用正则表达式匹配数字边界，并以此进行分割
      return str.split(/(\d+)/);
    },
    isNumberAtTheBeginning(str) {
      // 开头
      return /^\d/.test(str);
    },
    isNumberAtTheEnd(str) {
      // 结尾
      return /\d$/.test(str);
    },
    getuserchAll(){
      // 获取所有用户的查回记录
      axios.get('https://jqr.ts28.co/getuserch').then(res=>{
        this.userchjl =  res.data.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    getusernumAll(){
      // 获取所有用户的账单数据
      axios.get('https://jqr.ts28.co/getusernum').then(res=>{
          // 获取成功
          this.usernumjl =  res.data.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    shuaxls(){
      this.getuserxiazhujlAll()
    },
    getuserxiazhujlAll(){
      // 获取所有用户的账单数据
      axios.get('https://jqr.ts28.co/getzhongjiang').then(res=>{
          // 获取成功
          this.userxiazhujl =  res.data.data;
      }).catch(err=>{
        console.log(err)
      })
    },
    postuserch(data){
      // 新增用户查回记录
      axios.post('https://jqr.ts28.co/userch',data).then(res=>{
          // 新增成功
          this.userchjl.push(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    postuserxiazhu(data){
      axios.post('https://jqr.ts28.co/userxiazhu',[data]).then(res=>{
          // 新增成功
          console.log(res)
      }).catch(err=>{
        console.log(err)
      })
    },
    postusernum(data){
      // 新增用户账单
      axios.post('https://jqr.ts28.co/usernum',data).then(res=>{
          // 新增成功
          this.usernumjl.push(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    postzhongjiang(data){
      axios.post('https://jqr.ts28.co/zhongjiang',[data]).then(res=>{
          // 修改成功，要刷新用户账单
          console.log(res)
      }).catch(err=>{
        console.log(err)
      })
    },
    putusernum(data){
      // 修改用户账单金额
      console.log(data)
      axios.put('https://jqr.ts28.co/usernum',data).then(res=>{
          // 修改成功，要刷新用户账单
          if(res.data.code == 200){
            let flag = this.usernumjl.findIndex(item=>item.userid == data.userid)
            this.usernumjl[flag] = res.data.data
          }
      }).catch(err=>{
        console.log(err)
      })
    },
    puusernumall(data){
      axios.put('https://jqr.ts28.co/usernumall',data).then(res=>{
          // 修改成功，要刷新用户账单
          if(res.data.code == 200){
            this.usernumjl = res.data.data
          }
      }).catch(err=>{
        console.log(err)
      })
    },
    deletech(id){
      axios.delete('https://jqr.ts28.co/delech/'+id)
        .then(response => {
          if(response.data.code == 200){
            this.userchjl = this.userchjl.filter(item=>item.id != id)
                ElMessage({
                message: '撤回成功',
                type: 'success',
              })
              console.log('Success:', response);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    handleEdit(index,row){
      console.log(index)
      // 点击完成了，要修改用户账单
      let user = this.usernumjl.filter(item=>item.userid == row.userid)
      // 获取到了用户的详细
      if(row.type === '查'){
        // 这是查
        // 判断是否新加的用户
        if(user.length === 0 ){
        // 是新用户，需要post
          user=[{
            nick: row.nick,
            num: row.num,
            userid: row.userid,
            djnum:'0',
          }]
          // 上传到数据库
          this.postusernum(user[0])
        }else{
          // 是老用户 需要修改put
          user[0].num = parseInt(user[0].num)+parseInt(row.num)
          // 上传到数据库
          this.putusernum(user[0])
        }
        // 发送上分成功通知
        this.postuserch(row)
        setTimeout(()=>{
        let sf = this.userchjl.filter(item=>item.userid === row.userid && item.type == '查')
        let hui = this.userchjl.filter(item=>item.userid === row.userid && item.type == '回')
        let sfls = sf.reduce((accumulator, current) => parseInt(accumulator) +parseInt(current.num), 0);
        let huils = hui.reduce((accumulator, current) => parseInt(accumulator) +parseInt(current.num), 0);
        let resmsg = '@' + row.nick + '上分'+row.num+'成功'+'\n'+'账单金额：'+user[0].num+'\n'+'冻结金额：'+user[0].djnum+'\n'+'可用余额：'+(parseInt(user[0].num)-parseInt(user[0].djnum))
        resmsg = resmsg+'\n'+'总上分流水:'+sfls + '\n'+'总下分流水:'+huils+'\n'+'当前总盈亏:'+(parseInt(user[0].num)-parseInt(sfls)+parseInt(huils))
        let rqmsg = this.chat.createTextAtMessage({
          to: this.xuanGroupList.groupID,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,
          payload: {
            text: resmsg,
            // 'denny' 'lucy' 都是 userID，而非昵称
            atUserList: [row.userid]
          },
        })
        this.chat.sendMessage(rqmsg).then(imResponse => {
          console.log(imResponse)
        }).catch(err => {
          console.log(err)
        })
        },1000)
      }else if(row.type == '回'){
        // 这是处理回的信息
        user[0].num = parseInt(user[0].num)-parseInt(row.num)
        user[0].djnum = parseInt(user[0].djnum)-parseInt(row.num)
        this.putusernum(user[0])
        // 发送下分成功通知
        this.postuserch(row)
        let resmsg = '@' + row.nick + '回分'+row.num+'成功,请注意查收'
        let rqmsg = this.chat.createTextAtMessage({
          to: this.xuanGroupList.groupID,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,
          payload: {
            text: resmsg,
            // 'denny' 'lucy' 都是 userID，而非昵称
            atUserList: [row.userid]
          },
        })
        this.chat.sendMessage(rqmsg).then(imResponse => {
          console.log(imResponse)
        }).catch(err => {
          console.log(err)
        })
      }
      // 消除查回记录
      this.userch.splice(index,1)
    },
    handleDelete(index,row){
      console.log(index)
      console.log(row)
       // 点击删除了，要修改当前查回
      this.userch.splice(index,1)
    },
    reAll(){
      // 冻结金额要减去
      this.xiazhumingxi.forEach(item=>{
       let  user = this.usernumjl.filter(list=>list.userid === item.userid)
       user[0].djnum = parseInt(user[0].djnum)-parseInt(item.ls)
       this.putusernum(user[0])
      })
      this.xiazhumingxi=[]
      this.xiazhu=[]
      ElMessage({
          message: '全部删除本期下注成功',
          type: 'success',
        })
    },
    shanchuxiazhu(index,row){
      console.log(index)
      // 冻结金额要减去
      let  user = this.usernumjl.filter(list=>list.userid === row.userid)
      user[0].djnum = parseInt(user[0].djnum)-parseInt(row.ls)
      this.putusernum(user[0])
      this.xiazhumingxi = this.xiazhumingxi.filter(item=>item.userid !== row.userid)
      this.xiazhu = this.xiazhu.filter(item=>item.userid !== row.userid)
      
      ElMessage({
          message: '删除用户'+row.nick+row.userid+'本期下注成功',
          type: 'success',
        })
    },
    addadv(){
      this.adv.push({text: this.textarea2})
      this.textarea2=''
      ElMessage({
          message: '新增广告成功',
          type: 'success',
        })
        this.dialogTableVisible = false
    },
    sendadv(index,row){
      console.log(index)
      let resmsg = row.text
        let rqmsg = this.chat.createTextMessage({
          to: this.xuanGroupList.groupID,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,
          payload: {
            text: resmsg,
            // 'denny' 'lucy' 都是 userID，而非昵称
          },
        })
        this.chat.sendMessage(rqmsg).then(imResponse => {
          console.log(imResponse)
          ElMessage({
          message: '发送成功',
          type: 'success',
        })
        }).catch(err => {
          console.log(err)
        })
    },
    readv(index,row){
      console.log(row)
      this.adv.splice(index,1);
      ElMessage({
          message: '删除成功',
          type: 'success',
        })
    },
    chech(index,row){
      console.log(row)
      console.log(index)
      if(row){
        if(row.type == '查'){
          // 先撤回下注
          let xiazhuls = this.xiazhumingxi.filter(item=>item.userid === row.userid)
          this.xiazhumingxi = this.xiazhumingxi.filter(item=>item.userid!==row.userid)
          this.xiazhu = this.xiazhu.filter(item=>item.userid!==row.userid)
          // 撤回金额
          let user = this.usernumjl.filter(item=>item.userid ===row.userid)
          user[0].num = parseInt(user[0].num)-parseInt(row.num)
          // 冻结金额回档
          if(xiazhuls.length>0){
            user[0].djnum = parseInt(user[0].djnum)-parseInt(xiazhuls[0].ls)
          }
          this.putusernum(user[0])
          // 查回记录删除
          this.userchjl.filter(item=>item.id!==row.id)
        }
        if(row.type == '回'){
          // 金额回档
          let user = this.usernumjl.filter(item=>item.userid ===row.userid)
          user[0].num = parseInt(user[0].num)+parseInt(row.num)
          this.putusernum(user[0])
        }
        this.deletech(row.id)
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  margin: 0 0;
}

.demo-tabs .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.el-table{
  display: flex;
  flex-direction: column;
}
.el-table__body-wrapper {
  order: 1;
}
</style>

